<template>
  <div class="media">
    <v-media-iterator />
  </div>
</template>

<script>
import VMediaIterator from "../../components/media/VMediaIterator.vue";

export default {
  name: "Media",

  middleware: ["auth", "check-first-config"],

  components: { VMediaIterator },

  metaInfo() {
    return { title: this.$t("Media.Media") };
  },
};
</script>